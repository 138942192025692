import React from "react";

import WindowTitleComponent from "../WindowTitleComponent";

import AlbumList from "./AlbumList";
import Album from "./Album";
import "./PhotoComponent.css";

export default (props) => {
  //Some styles are overridden in App.css
  if (props.albumId == null) {
    return <AlbumList/>
  } else {
    return (
        <>
          <WindowTitleComponent title="Foto's" />
          <Album
              updateAppTitle={props.updateAppTitle}
              album={props.albumId}
          />
        </>
    );
  }
}
